<template>
  <b-col
    lg="4"
    md="6"
    cols="12"
  >
    <b-card no-body>
      <b-overlay
        :show="loadingCurrentSaldo"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0.3"
      >
        <b-card-body class="h-[10rem] md:h-[12rem]">
          <div class="d-flex mb-1 justify-content-between">
            <div class="d-flex text-2xl font-semibold align-self-center">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg"
                class="align-self-center"
              >
              <span class="align-self-center mx-[6px]">Saldo KomPay</span>
              <img
                id="balanceTooltip"
                class="align-self-center"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              >
              <b-popover
                triggers="hover"
                target="balanceTooltip"
                placement="bottomleft"
              >Saldo kamu saat ini.</b-popover>
            </div>
            <div
              v-if="profile.role_id === 4"
              class="flex align-self-center"
            >
              <img
                id="detailSaldo"
                type="button"
                class="border border-light p-[5px] hover:bg-[#E2E2E2] rounded-lg"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/document-text.svg"
                alt="setting"
                @click="$router.push('/keuangan/saldo/detail')"
              >
              <img
                id="paymentSettings"
                type="button"
                class="border border-light p-[5px] hover:bg-[#E2E2E2] rounded-lg"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/setting-2.svg"
                alt="setting"
                @click="$router.push('/payment-setting')"
              >
              <b-popover
                triggers="hover"
                target="paymentSettings"
                placement="top"
              >Pengaturan Pembayaran</b-popover>
              <b-popover
                triggers="hover"
                target="detailSaldo"
                placement="top"
              >Detail Rincian Saldo</b-popover>
            </div>
          </div>
          <span class="text-4xl font-semibold">Rp {{ saldo.toLocaleString('id-ID') }}</span>
          <div class="d-flex mt-2">
            <img
              class="w-6"
              src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
              alt="kompoint"
            >
            <strong class="mx-[7px]">Kompoints</strong>
            <strong class="text-warning">: {{ profile.user_kompoints.toLocaleString('id-ID') }}</strong>
          </div>
        </b-card-body>
        <b-card-footer
          footer-bg-variant="primary"
          footer-text-variant="white"
          class="rounded-lg p-0"
        >
          <b-row class="p-[10px]">
            <b-col
              cols="4"
              class="d-flex justify-content-center cursor-pointer p-0"
              @click="handleTopup()"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/send-square.svg"
                alt="Top Up"
                class="my-auto mr-[6px]"
              >
              <span class="my-auto">Top Up</span>
            </b-col>
            <b-col
              cols="4"
              class="d-flex justify-content-center cursor-pointer p-0"
              @click="getModalWithdrawal()"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/receive-square.svg"
                alt="Tarik Saldo"
                class="my-auto mr-[6px]"
              >
              <span class="my-auto">Tarik Saldo</span>
            </b-col>
            <b-col
              cols="4"
              class="d-flex flex-col justify-center cursor-pointer p-0"
              @click="$router.push('/voucher')"
            >
              <div class="flex items-center justify-center gap-3">
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/card-send.svg"
                  alt="Detail"
                  class="my-auto mr-[6px]"
                >
                <span>Bayar</span>
              </div>
              <small class="text-center">
                Redeem Voucher
              </small>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-overlay>
    </b-card>
    <b-modal
      id="modalTopUp"
      ref="modalTopUp"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <p class="text-2xl text-center font-semibold my-1">
        Top Up Saldo
      </p>
      <b-form class="mb-1">
        <b-form-group>
          <label
            for="nominalTopUp"
            class="font-semibold"
          >Nominal</label>
          <b-form-input
            id="nominalTopUp"
            v-model="nominalTopUp"
            placeholder="minimal Rp10.000,-"
          />
          <small
            v-if="formatNumber(nominalTopUp) < 10000"
            class="text-danger"
          >*Minimal top up Rp 10.000</small>
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <b-btn
              variant="outline-primary"
              block
              :disabled="loadingTopUp"
              @click="$refs.modalTopUp.hide()"
            >
              Batal
            </b-btn>
          </b-col>
          <b-col cols="6">
            <b-btn
              variant="primary"
              block
              :disabled="Number.isNaN(formatNumber(nominalTopUp))
                || formatNumber(nominalTopUp) < 10000
                || loadingTopUp"
              @click="checkIsValid(nominalTopUp) ? topUpBalance() : false"
            >
              <b-spinner
                v-if="loadingTopUp"
                class="mr-[6px]"
                small
              />
              Top Up Sekarang
            </b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      ref="modalWithdrawal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <p class="text-2xl text-center font-semibold mt-1">
        Penarikan Saldo
      </p>
      <p class="text-center mb-1">
        Masukkan nominal penarikan dan bank transfer kamu, dan pastikan nomor rekening sesuai yang kamu tuju
      </p>
      <b-form class="mb-1">
        <b-form-group>
          <label
            for="nominalWithdrawal"
            class="font-semibold"
          >Nominal</label>
          <b-form-input
            id="nominalWithdrawal"
            v-model="nominal"
            placeholder="minimal Rp10.000,-"
          />
          <small
            v-if="formatNumber(nominal) < 10000"
            class="text-danger"
          >*Minimal penarikan Rp 10.000</small>
          <small
            v-else-if="formatNumber(nominal) > saldo"
            class="text-danger"
          >*Saldo kamu tidak mencukupi</small>
        </b-form-group>
        <div v-if="!withdrawalAllow">
          <p class="mb-1">
            <b>Sisa saldo : Rp {{ remainingWithdrawal.toLocaleString('id-ID') }},-</b> misal ditarik
          </p>
          <div class="bg-[#FFEDED] p-1 mb-1 rounded-lg">
            Maaf kami batasi maksimal penarikan akunmu agar tersisa saldo setidaknya sebesar <b class="text-danger">Rp {{ idealBalance.toLocaleString('id-ID') }},-</b> buat menghindari minus
          </div>
          <div class="border-dashed border-2 rounded-lg p-1 mb-1">
            <p class="mb-1">
              Angka ini didapat dari total nilai ongkir berangkat atas orderan yang sudah diputuskan RETUR namun barangnya <b>belum sampai</b> ke gudangmu yakni <b>Rp {{ potencyRetur.toLocaleString('id-ID') }},-</b> total tersebut kami bandingkan dengan total <b>potensi pendapatanmu</b> yang akan diterima dari orderan yang berstatus <b>DIKIRIM</b> yang pengirimannya sejauh ini belum ada problem yakni <b class="text-success">Rp {{ potencyIncome.toLocaleString('id-ID') }},-</b>
            </p>
            <p>Di Komship enaknya tuh kalau Retur barang <b>udah balik</b> lagi ke gudang baru kita minuskan ongkir berangkatnya, belum lagi bisa di <b>claim</b> pula.</p>
          </div>
        </div>
        <b-form-group>
          <label
            for="nominalTopUp"
            class="font-semibold"
          >Rekening</label>
          <v-select
            v-model="selectedRekTujuan"
            label="text"
            :reduce="items => items.value"
            :options="rekTujuanOptions"
          >
            <span
              v-if="rekTujuanOptions.length === 0"
              slot="no-options"
            >
              Anda belum menambahkan rekening bank
            </span>
          </v-select>
          <small>Pastikan rekening yang kamu pilih sudah benar</small>
        </b-form-group>
        <BRow v-if="profile.is_komcards !== 1">
          <BCol lg="12">
            <BAlert
              show
              variant="info"
              class="p-2"
            >
              <div class="flex flex-row items-center justify-between">
                <div>
                  <div class="flex items-center gap-3">
                    <div class="text-black font-medium text-[16px]">
                      Telah hadir
                    </div>
                    <img
                      src="https://storage.googleapis.com/komerce/assets/LP-Komcard/Logo-navbar.svg"
                      alt="Komerce"
                      width="100"
                    >
                  </div>
                  <div class="text-black font-normal text-[12px]">Ribet narik dana buat topup iklan? Pake Komcards!
                    <br>
                    Saldo Komship & bayar iklan saling terhubung
                  </div>
                </div>
                <a
                  href="https://komcards.id/integrasi-komship/#more"
                  target="_blank"
                >
                  <BButton
                    size="sm"
                    variant="outline-primary"
                  >
                    Cobain
                  </BButton>
                </a>
              </div>
            </BAlert>
          </BCol>
        </BRow>
        <b-row>
          <b-col cols="6">
            <b-btn
              variant="outline-primary"
              block
              @click="$refs.modalWithdrawal.hide()"
            >
              Batal
            </b-btn>
          </b-col>
          <b-col cols="6">
            <b-btn
              variant="primary"
              block
              :disabled="selectedRekTujuan === null
                || Number.isNaN(formatNumber(nominal))
                || formatNumber(nominal) < 10000
                || formatNumber(nominal) > saldo
                || loadingWithdrawal
                || selectedRekTujuan === ''"
              @click="withdrawalBalance(nominal)"
            >
              <b-spinner
                v-if="loadingWithdrawal"
                class="mr-[6px]"
                small
              />
              Ajukan Penarikan
            </b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      ref="modalVerifyPIN"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <p class="text-2xl text-center font-semibold mt-1">
        Verifikasi PIN
      </p>
      <p class="text-center mb-2">
        Mohon verifikasi identitas kamu dengan memasukkan PIN
      </p>
      <div
        id="pin"
        class="flex flex-row justify-center text-center px-4 mb-1"
      >
        <b-input
          v-for="(digit, index) in pinDigits"
          :key="index"
          ref="pinInput"
          v-model="pinDigits[index]"
          class="text-center border-b-4 m-[5px]"
          maxlength="1"
          @input="handleInputPIN(index, $event)"
          @keydown="handleKeydownPIN(index, $event)"
        />
      </div>
      <p
        class="text-center text-primary font-semibold cursor-pointer mb-2"
        @click="$router.push('setting-kompship/pin')"
      >
        Lupa PIN?
      </p>
      <b-row class="mb-1">
        <b-col cols="6">
          <b-button
            variant="outline-primary"
            block
            @click="
              $refs.modalVerifyPIN.hide(),
              $refs.modalWithdrawal.show()"
          >
            Kembali
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            variant="primary"
            block
            :disabled="pinDigits.join('').length < 6 || loadingVerifyPIN"
            @click="withdrawalRequest()"
          >
            <b-spinner
              v-if="loadingVerifyPIN"
              class="mr-[6px]"
              small
            />
            Konfirmasi
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <PaymentMethods @methodSelected="methodSelected" />
    <ScanQRIS
      :qr-bill-detail="qrBillDetail"
      @cancelPayment="cancelPayment"
      @paymentSuccess="paymentSuccess"
      @hidden="qrBillDetail = {}"
    />
    <ModalPaymentExist
      @continuePayment="continuePayment"
      @cancelPayment="cancelPayment"
    />
  </b-col>
</template>
<script>
import vSelect from 'vue-select'
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import '@core/scss/vue/libs/vue-select.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PaymentMethods from './modal/PaymentMethod.vue'
import ScanQRIS from './modal/ScanQRIS.vue'
import ModalPaymentExist from './modal/ModalPaymentExist.vue'

export default {
  components: {
    vSelect,
    PaymentMethods,
    ScanQRIS,
    ModalPaymentExist,
  },
  data() {
    return {
      loadingTopUp: false,
      loadingWithdrawal: false,
      loadingVerifyPIN: false,
      remainingWithdrawal: 0,
      idealBalance: 0,
      potencyIncome: 0,
      potencyRetur: 0,
      withdrawalAllow: true,
      pinDigits: ['', '', '', '', '', ''],
      dataBillExist: null,
      qrBillDetail: {},
    }
  },
  computed: {
    ...mapState('dashboard', ['saldo', 'loadingCurrentSaldo', 'profile']),
    ...mapGetters('saldo', ['rekenings', 'rekening', 'rekTujuanOptions']),
    ...mapFields('saldo', [
      'nominalTopUp',
      'selectedRekTujuan',
      'nominal',
      'pin',
    ]),
  },
  watch: {
    nominal(newValue) {
      const result = newValue.replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      this.$nextTick(() => {
        this.nominal = `Rp ${result}`
      })
    },
    nominalTopUp(newValue) {
      const result = newValue.replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      this.$nextTick(() => {
        this.nominalTopUp = `Rp ${result}`
      })
    },
  },
  mounted() {
    if (this.$route.params.topup === true) {
      this.handleTopup()
    }
  },
  methods: {
    formatNumber(value) {
      // eslint-disable-next-line radix
      const result = parseInt(value.replace(/[^0-9]/g, ''))
      return result
    },
    async topUpBalance() {
      if (this.formatNumber(this.nominalTopUp) > 500000) {
        this.loadingTopUp = true
        try {
          const response = await this.$store.dispatch('saldo/newTopUpSaldo')
          const { data } = response
          if (!data.status) throw data
          try {
            window.open(data.data.invoice_url, '_blank').focus()
          } catch (e) {
            this.$refs.modalTopUp.hide()
            this.loadingTopUp = false
            this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
          }
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
          this.$swal({
            html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
            confirmButtonText: 'Cek Rincian Saldo',
            confirmButtonClass: 'btn btn-primary',
          }).then(then => {
            if (then.isConfirmed) {
              this.$router.push('/keuangan/saldo/detail')
            }
          })
        } catch (e) {
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        } finally {
          this.$store.commit('saldo/UPDATE_NOMINAL', '')
        }
      } else {
        this.$bvModal.show('paymentMethod')
      }
    },
    async checkWithdrawal() {
      try {
        const response = await this.$http_komship.get(
          '/v1/partner/withdrawal/check-possible-withdraw', {
            params: {
              withdrawal_request_nominal: this.formatNumber(this.nominal),
            },
          },
        )
        const { data } = response.data
        this.remainingWithdrawal = this.saldo - this.formatNumber(this.nominal)
        this.idealBalance = data.ideal_balance
        this.potencyIncome = data.potency_income
        this.potencyRetur = data.potency_retur
        this.withdrawalAllow = data.withdraw_possibilites === 1
      } catch (error) {
        console.log(error)
      }
    },
    getModalTopUp() {
      this.nominalTopUp = ''
      this.$refs.modalTopUp.show()
    },
    getModalWithdrawal() {
      if (this.profile.role_name === 'Talent Global') {
        this.$swal({
          text: 'Maaf akun kamu tidak memiliki akses untuk penarikan saldo.',
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          allowOutsideClick: true,
          confirmButtonText: 'Kembali',
          confirmButtonClass: 'btn btn-primary',
        })
      } else if (this.rekTujuanOptions.length < 1) {
        this.$swal({
          text: 'Maaf kamu belum menambahkan rekening untuk penarikan saldo.',
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          allowOutsideClick: false,
          confirmButtonText: 'Tambah Rekening',
          confirmButtonClass: 'btn btn-primary',
        }).then(response => {
          if (response.isConfirmed) {
            this.$router.push({ name: 'kompship-rekening-bank' })
          }
        })
      } else {
        this.loadingWithdrawal = false
        this.remainingWithdrawal = 0
        this.idealBalance = 0
        this.potencyIncome = 0
        this.potencyRetur = 0
        this.withdrawalAllow = true
        this.nominal = ''
        this.selectedRekTujuan = ''
        this.$refs.modalWithdrawal.show()
      }
    },
    async withdrawalBalance(nominal) {
      const disabledWithdrawal = this.formatNumber(nominal) < 10000 || this.formatNumber(nominal) > this.saldo
      if (!disabledWithdrawal) {
        this.loadingWithdrawal = true
        try {
          await this.checkWithdrawal()
          if (this.withdrawalAllow) {
            this.loadingWithdrawal = false
            this.pinDigits = ['', '', '', '', '', '']
            this.$refs.modalWithdrawal.hide()
            this.$refs.modalVerifyPIN.show()
          }
        } catch (error) {
          this.loadingWithdrawal = false
          console.error(error)
        }
      }
    },
    handleInputPIN(index, event) {
      if (event !== '') {
        setTimeout(() => {
          this.$refs.pinInput[index].type = 'password'
        }, 800)
        if (index !== this.pinDigits.length - 1) {
          this.$refs.pinInput[index + 1].focus()
        }
      }
    },
    handleKeydownPIN(index, event) {
      const { keyCode } = event
      if (
        (keyCode >= 48 && keyCode <= 57)
        || (keyCode >= 96 && keyCode <= 105)
        || keyCode === 8
        || keyCode === 46
        || (keyCode >= 37 && keyCode <= 40)
      ) {
        if (event.key === 'Backspace') {
          this.$refs.pinInput[index].type = 'text'
          if (index !== 0 && event.target.value === '') {
            this.$refs.pinInput[index - 1].focus()
          }
        }
      } else {
        event.preventDefault()
      }
    },
    async withdrawalRequest() {
      const verify = await this.verifyPIN()
      if (!verify) return
      try {
        const withdrawalRequest = await this.$store.dispatch(
          'saldo/withdrawalRequest',
        )
        const { code } = withdrawalRequest.data
        const selectedAccount = this.rekenings.find(items => items.id === this.selectedRekTujuan)
        if (code === 200) {
          this.$swal({
            title: '<span class="font-semibold h4">Penarikan Saldo Berhasil</span>',
            text: `Penarikan sebesar ${this.nominal} akan segera dikirim ke rekening atas nama ${selectedAccount.nama} - ${selectedAccount.bank} dalam 1x24 jam`,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/success.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        }
      } catch (error) {
        let message = ''
        if (error.response.status === 400) message = 'Maaf, kamu tidak bisa melakukan penarikan saldo dikarenakan kamu masih memiliki antrian penarikan yang belum disetujui.'
        else message = 'Penarikan tidak dapat dilakukan karena terjadi kesalahan, tunggu beberapa saat dan mulai penarikan kembali'
        this.$swal({
          title: '<span class="font-semibold h4">Penarikan Saldo Gagal</span>',
          text: message,
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          confirmButtonText: 'Oke',
          confirmButtonClass: 'btn btn-primary',
        })
      }
    },
    async verifyPIN() {
      this.loadingVerifyPIN = true
      this.pin = this.pinDigits.join('')
      try {
        const checkPin = await this.$store.dispatch('saldo/checkPin')
        const { data } = checkPin.data
        this.loadingVerifyPIN = false
        if (data.is_match) {
          this.$refs.modalVerifyPIN.hide()
          return true
        }
        this.pinDigits = ['', '', '', '', '', '']
        this.$swal({
          title: '<span class="font-semibold h4">Verifikasi PIN Gagal</span>',
          text: 'PIN yang kamu masukkan belum benar, coba lagi',
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          allowOutsideClick: false,
          confirmButtonText: 'Lupa PIN',
          confirmButtonClass: 'btn btn-outline-primary text-primary',
          confirmButtonColor: '#FFFFFF',
          showCancelButton: true,
          cancelButtonText: 'Coba Lagi',
          cancelButtonClass: 'btn btn-primary',
        }).then(response => {
          if (response.isConfirmed) {
            this.$router.push('setting-kompship/pin')
          }
        })
        return false
      } catch (error) {
        this.loadingVerifyPIN = false
        return false
      }
    },
    async methodSelected(method) {
      if (method === 'qris') {
        try {
          const response = await this.$store.dispatch('saldo/qrisTopup')
          const { data } = response

          if (!data.status) throw data

          if (data.data) {
            const {
              amount,
              expires_at,
              qr_string,
              id,
            } = data.data

            this.qrBillDetail = {
              amount,
              expire: expires_at,
              qrString: qr_string,
              qrId: id,
            }

            this.$bvModal.hide('paymentMethod')
            this.$bvModal.show('scanQris')
          }
        } catch (e) {
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        }
      } else {
        this.$bvModal.hide('paymentMethod')

        const result = this.formatNumber(this.nominalTopUp)
        this.nominalTopUp = `Rp ${result}`
        this.loadingTopUp = true
        try {
          const response = await this.$store.dispatch('saldo/newTopUpSaldo', true)
          const { data } = response
          if (!data.status) throw data
          try {
            this.$refs.modalTopUp.hide()
            window.open(data.data.invoice_url, '_blank').focus()
          } catch (e) {
            this.$refs.modalTopUp.hide()
            this.loadingTopUp = false
            this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
          }
          this.$refs.modalTopUp.hide()
          this.$bvModal.hide('paymentMethod')
          this.loadingTopUp = false
          this.$swal({
            html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
            confirmButtonText: 'Cek Rincian Saldo',
            confirmButtonClass: 'btn btn-primary',
          }).then(then => {
            if (then.isConfirmed) {
              this.$router.push('/keuangan/saldo/detail')
            }
          })
        } catch (e) {
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        } finally {
          this.$store.commit('saldo/UPDATE_NOMINAL', '')
        }
      }
    },
    async handleTopup() {
      try {
        const response = await this.$store.dispatch('saldo/checkActiveBill')
        const { data } = response
        if (data.data.have_active_bill) {
          this.dataBillExist = data.data
          this.$bvModal.show('modalPaymentExist')
        } else {
          this.getModalTopUp()
        }
        if (!data.status) throw data
      } catch (e) {
        this.$swal({
          title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
          text: e.message,
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          confirmButtonText: 'Oke',
          confirmButtonClass: 'btn btn-primary',
        })
      }
    },
    async continuePayment() {
      if (!this.dataBillExist.invoice_xendit_url) {
        const {
          qr_amount,
          qr_expire_date,
          qr_xendit_qrstring,
          qr_xendit_id,
        } = this.dataBillExist

        this.qrBillDetail = {
          amount: qr_amount,
          expire: qr_expire_date,
          qrString: qr_xendit_qrstring,
          qrId: qr_xendit_id,
        }

        this.$bvModal.show('scanQris')
      } else {
        this.$bvModal.hide('modalPaymentExist')
        try {
          window.open(this.dataBillExist.invoice_xendit_url, '_blank').focus()
        } catch (e) {
          this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
        }
        this.$refs.modalTopUp.hide()
        this.loadingTopUp = false
        this.$swal({
          html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          confirmButtonText: 'Cek Rincian Saldo',
          confirmButtonClass: 'btn btn-primary',
        }).then(then => {
          if (then.isConfirmed) {
            this.$router.push('/keuangan/saldo/detail')
          }
        })
      }
    },
    async cancelPayment(qr) {
      try {
        const response = await !qr && this.dataBillExist.invoice_xendit_id ? this.$store.dispatch('saldo/cancelActiveBill', this.dataBillExist.invoice_xendit_id) : this.$store.dispatch('saldo/cancelQrBill', qr || this.dataBillExist.qr_xendit_id)
        const { data } = await response
        if (data.code === 200) {
          this.toastSuccess('Berhasil membatalkan pembayaran')

          this.$bvModal.hide('modalPaymentExist')
          this.$bvModal.hide('scanQris')
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
        }
        if (!data.status) throw data
      } catch ({ response }) {
        if (response.status === 400 || (response.status === 404 && response.data.data.detail === 'could not find invoice by id')) {
          this.toastSuccess('Pembayaran sudah berhasil dilakukan')
          this.$bvModal.hide('modalPaymentExist')
          this.$store.dispatch('dashboard/getBalanceSummary')
        } else {
          this.$swal({
            title: '<span class="font-semibold h4">Pembatalan Pembayaran Gagal</span>',
            text: response.data.data,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        }
      }
    },
    paymentSuccess(message) {
      this.toastSuccess(message)

      this.$store.dispatch('dashboard/getBalanceSummary')
      this.$bvModal.hide('scanQris')
      this.$bvModal.hide('modalPaymentExist')
      this.loadingTopUp = false
      this.$refs.modalTopUp.hide()
    },
    toastFailed(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed',
          icon: 'AlertCircleIcon',
          text,
          variant: 'danger',
        },
      }, 2000)
    },
    toastSuccess(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'SuccessIcon',
          text,
          variant: 'success',
        },
      }, 1000)
    },
    checkIsValid(nominalTopUp) {
      if (Number.isNaN(this.formatNumber(nominalTopUp)) || this.formatNumber(nominalTopUp) < 10000 || this.loadingTopUp) {
        return false
      // eslint-disable-next-line no-else-return
      } else {
        return true
      }
    },
  },
}
</script>
